export const DOTS = '...';

const range = (start: number, end: number): (string | number)[] => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

interface IPagination {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}

export const setArray = (
  pagination: IPagination
): (string | number)[] | undefined => {
  const siblingCount = 1;
  const totalPageCount = Math.ceil(pagination.total / pagination.pageSize);

  // Pages count is determined as siblingCount + firstPage + lastPage + pagination.page + 2*DOTS
  const totalPageNumbers = siblingCount + 5;

  /*
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
      */
  if (totalPageNumbers >= totalPageCount) {
    return range(1, totalPageCount);
  }

  const leftSiblingIndex = Math.max(pagination.page - siblingCount, 1);
  const rightSiblingIndex = Math.min(
    pagination.page + siblingCount,
    totalPageCount
  );

  /*
        We do not want to show dots if there is only one position left 
        after/before the left/right page count as that would lead to a change if our pagination
        component size which we do not want
      */
  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;

  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftItemCount = 3 + 2 * siblingCount;
    const leftRange = range(1, leftItemCount);

    return [...leftRange, DOTS, totalPageCount];
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightItemCount = 3 + 2 * siblingCount;
    const rightRange = range(
      totalPageCount - rightItemCount + 1,
      totalPageCount
    );
    return [firstPageIndex, DOTS, ...rightRange];
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
    const middleRange = range(leftSiblingIndex, rightSiblingIndex);
    return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
  }
};
