import { DateHeaderProps } from 'react-big-calendar';

const CustomDateHeader: React.FC<DateHeaderProps> = (props): JSX.Element => {
  return (
    <div className="rbc-date-cell w-full h-full flex items-center justify-end cursor-pointer">
      {props.label}
    </div>
  );
};

export default CustomDateHeader;
