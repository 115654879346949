import * as React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useSelector } from 'react-redux';
import { IAllReducers } from 'redux/store';
import { useCallback } from 'react';
// import Toolbar from './Leave/Calendar/Toolbar';
// import HeaderMonth from './Leave/Calendar/HeaderMonth';

interface Props {
  events: IEventCarendar[];
}

export interface IEventCarendar {
  title: string;
  start: Date;
  end: Date;
  description?: string;
  allDay?: boolean;
  iduser?: number;
}

const CalendarHoliday: React.FunctionComponent<Props> = (props) => {
  const localizer = momentLocalizer(moment);
  const auth = useSelector((state: IAllReducers) => state.auth);

  const handleSelectEvent = useCallback((event) => {
    console.log(event);
    if (event.description !== undefined) {
      window.alert(event.description);
    }
  }, []);

  // const { components } = useMemo(
  //   () => ({
  //     components: {
  //       toolbar: CustomToolbar
  //     },
  //     defaultDate: new Date(2015, 3, 13)
  //   }),
  //   []
  // );

  return (
    <Calendar
      localizer={localizer}
      onSelectEvent={handleSelectEvent}
      events={props.events}
      startAccessor="start"
      endAccessor="end"
      style={{
        height: '600px'
      }}
      eventPropGetter={(event) => {
        const style = {
          backgroundColor: '#ff3333'
        };

        if (event.iduser && event.iduser !== auth.user?.id) {
          style.backgroundColor = '#FF00FF';
        } else if (event.iduser && event.iduser === auth.user?.id) {
          style.backgroundColor = '#0000FF';
        }

        return { style };
      }}
    />
  );
};

export default CalendarHoliday;
